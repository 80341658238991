@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://site-assets.fontawesome.com/releases/v6.0.0/css/all.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/css/bootstrap.min.css");
@import url("https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css");
@import url("./assets/css/core.css");
@import url("./assets/css/pages/page-auth.css");
@import url("./assets/css/pages/page-icons.css");
@import url("./assets/css/pages/page-misc.css");
@import url("./assets/css/index.css");
@import url("./assets/css/theme-default.css");

button {
  border: none;
  outline: none;
  background: none;
  cursor: pointer !important;
}

.winner-section{
  display: flex;
  justify-content: space-between;

}

::-webkit-scrollbar {
  /* width: 5px; */
}